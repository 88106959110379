/*---------------------

Table of contents

1. Loader
2. Helper classes 
3. Buttons
4. Navbar
5. Home
6. Features
7. Download
8. Product
9. Contact
10. Footer
11. Responsive

---------------------*/

/* ==========
   1. Basic
   ========== */

body,
html {
  height: 100%;
  width: 100%;
  max-width: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  background: transparent;
  color: #8f8f90;
  overflow-x: hidden !important;
  margin: auto;
  -webkit-font-smoothing: subpixel-antialiased;
}

p, ul, ol {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

p {
  margin: 0;
}

p + p {
  margin-top: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-family: 'Rubik', sans-serif;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 30px;
}

/*.hiddenHeader {*/
/*display: none;*/
/*margin: 0;*/
/*}*/
::selection {
  background: rgba(129, 174, 81, 0.5);
  color: #ffffff;
}

::-moz-selection {
  background: rgba(129, 174, 81, 0.5);
  color: #ffffff;
}

/* ==========
   2. Pre Loader
   ========== */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999999;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.spinner {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(185, 186, 190, 0.2);
  border-right: 5px solid rgba(185, 186, 190, 0.2);
  border-bottom: 5px solid rgba(185, 186, 190, 0.2);
  border-left: 5px solid #81ae51;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ==========
   3. Helper Classes
   ========== */

.section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.title {
  margin: -10px 0 40px 0;
  font-size: 44px;
  font-weight: normal;
  line-height: 1.2;
  color: #000;
}

.title > b {
  font-weight: 500;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-t-80 {
  margin-top: 80px !important;
}

.m-t-100 {
  margin-top: 100px !important;
}

.m-t-130 {
  margin-top: 130px !important;
}

.bg-gray {
  background-color: #f5f5f5;
}

.bg-highlight {
  background-color: #c8dd99;
}

/* ==========
   4. Bootstrap Reset
   ========== */

/* Text color */

.text-primary {
  color: #6da92e;
}

.text-muted {
  color: #e8e8e8;
}

.text-warning {
  color: #f5a623;
}

.text-white {
  color: #fff;
}

.text-gray {
  color: #8f8f90;
}

.text-black {
  color: #000;
}

/* Buttons */

.btn {
  padding: 12px 22px;
}

.btn-primary {
  background-color: #6da92e !important;
  border: 1px solid #6da92e !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.open > .dropdown-toggle.btn-primary {
  background-color: #6da92e !important;
  border: 1px solid #6da92e !important;
}

/* Icons */

.icon-document {
  background-image: url("../images/icons/checklist.png");
}

.icon-search {
  background-image: url("../images/icons/search.png");
}

.icon-plan {
  background-image: url("../images/icons/plan.png");
}

.icon-tablet {
  background-image: url("../images/icons/tablet.png");
}

.icon-phone {
  background-image: url("../images/icons/phone.png");
}

.icon-clock {
  background-image: url("../images/icons/clock.png");
}

.icon-document-search {
  background-image: url("../images/icons/document-search.png");
}

.icon-phone-in-hand {
  background-image: url("../images/icons/phone-in-hand.png");
}

.icon-phone-notification {
  background-image: url("../images/icons/phone-notification.png");
}

.icon-phone-search {
  background-image: url("../images/icons/phone-search.png");
}

.icon-folder {
  background-image: url("../images/icons/folder.png");
}

.icon-folder-opened {
  background-image: url("../images/icons/folder-opened.png");
}

.icon-home {
  background-image: url("../images/icons/home.png");
}

.icon-bell {
  background-image: url("../images/icons/bell.png");
}

/* ==========
   5. Navbar Custom
   ========== */

.sticky-navigation {
  transition: all .8s ease-in-out;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  padding: 0 !important;
  margin-bottom: 0 !important;
  -webkit-backface-visibility: hidden;
  top: -100px;
  box-shadow: 0 9px 23px 0 rgba(0, 0, 0, 0.2);
}

.nav-logo {
  height: auto;
  overflow: hidden;
  color: #000;
  padding: 0 !important;
  margin: 0 !important;
}

.nav-logo:focus {
  outline: none;
}

.sticky-navigation .nav-logo {
  height: 74px;
}

.sticky-navigation .nav-logo > img {
  margin-top: -32px;
}

.navbar-toggle {
  margin-top: 23px;
}

.navbar-toggle .icon-bar {
  background: #000;
}

.navbar {
  margin-bottom: 0;
}

.navbar-nav {
  float: left;
  margin-top: 11px;
  transition: all .3s ease-in-out;
}

.navbar-nav li a {
  padding: 3px 15px;
  margin: 7px 0;
  line-height: 30px;
  font-size: 16px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  color: #000;
  position: relative;
  background: transparent !important;
  transition: all .5s ease;
}

.navbar-nav li a:focus {
  outline: none;
}

.navbar-nav li a:hover {
  color: #000;
}

.navbar-nav li.active a {
  color: #8f8f90;
}

.navbar-toggle {
  padding: 5px 10px;
  margin-right: 10px;
}

.navbar-collapse.collapsing,
.navbar-collapse.in {
  background: url("../images/logo.png") no-repeat 0 -107px;
}

.navbar-collapse.collapsing .navbar-nav,
.navbar-collapse.in .navbar-nav {
  margin-top: 30px;
}

/* ==========
   6. Home
   ========== */

.home {
  position: relative;
  min-height: 796px;
  overflow: hidden;
  color: #ffffff;
  background-image: url("../images/home/bg.png");
  background-repeat: repeat;
}

.home > .overlay {
  position: absolute;
  background: linear-gradient(to bottom, #ffffff, transparent);
  opacity: 0.6;
  height: 80%;
  width: 100%;
  top: 0;
}

.home > .container.circles {
  position: relative;
}

.home .circle-1 {
  position: absolute;
  width: 212px;
  height: 212px;
  padding: 23px;
  top: 100px;
  left: 26%;
  background-image: url("../images/home/circle-1.png");
  background-repeat: no-repeat;
}

.home .circle-1 > img {
  width: 166px;
  height: 166px;
}

.home .circle-2 {
  position: absolute;
  width: 259px;
  height: 259px;
  padding: 21px 32px;
  top: 410px;
  left: -5%;
  background-image: url("../images/home/circle-2.png");
  background-repeat: no-repeat;
}

.home .circle-2 > img {
  width: 202px;
  height: 202px;
}

.home .circle-3 {
  position: absolute;
  width: 350px;
  height: 350px;
  padding: 25px;
  top: 534px;
  right: -12%;
  background-image: url("../images/home/circle-3.png");
  background-repeat: no-repeat;
}

.home .circle-3 > img {
  width: 298px;
  height: 298px;
}

.home > .container.header {
  padding-top: 19px;
  padding-bottom: 19px;
}

.home .navbar-collapse {
  margin-top: 31px;
}

.home .logo {
  width: 173px;
  height: 138px;
}

.home > .container.teaser {
  margin-top: 80px;
}

.home .phone-wrapper {
  height: 538px;
}

.home .phone {
  float: right;
  width: 708px;
  height: 538px;
  padding-left: 396px;
  padding-top: 56px;
  margin-right: -30px;
  background-image: url("../images/home/phone.png");
  background-repeat: no-repeat;
}

.home .phone > img {
  display: block;
  width: 215px;
  height: 382px;
}

.home h1 {
  line-height: 1.24;
  font-weight: normal;
  margin-top: 40px;
  margin-bottom: 40px;
}

.home p {
  font-size: 18px;
  line-height: 1.78;
  margin-top: 0;
}

.home .download-buttons {
  margin-top: 75px;
}

/* ==========
   6. Features
   ========== */

.feature-block {
  display: flex;
  flex-direction: row;
}

.feature-block-right {
  flex-direction: row-reverse;
}

.feature-block + .feature-block {
  margin-top: 70px;
}

.feature-icon {
  /*float: left;*/
  margin-top: -8px;
  margin-right: 31px;
}

.feature-icon > i {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center 0;
}

.feature-block-right .feature-icon {
  /*float: right;*/
  margin-right: 0;
  margin-left: 31px;
}

.feature-image {
  margin-bottom: 50px;
}

.feature-image > i {
  display: block;
  width: 283px;
  height: 283px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center 0;
}

.feature-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.2;
  color: #6da92e;
}

.feature-subtitle {
  margin-top: 16px;
  font-style: italic;
  color: #000000;
}

.feature-text {
  margin-top: 20px;
}

.feature-frame-text {
  width: 447px;
  height: 82px;
  padding: 16px 22px;
  background: url("../images/product/text-frame.png") no-repeat 0 0;
}

.text-right .feature-frame-text {
  margin-left: auto;
}

.feature-text + .feature-frame-text {
  margin-top: 45px;
}

.feature-list {
  padding: 0;
  margin: 20px 0 0 20px;
  list-style: circle;
}

.feature-list li {
  padding: 0 0 0 10px;
  margin: 0;
}

.feature-list li span {
  transition: all .5s ease; 
}

.feature-list li + li {
  margin: 20px 0 0 0;
}

.feature-list li.active {
  color: #6da92e;
}

.feature-list li.active span {
  color: #000;
}

.feature-photo {
  margin: -36px 0;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.feature-photo > img {
  display: block;
  width: 447px;
  height: 447px;
  border-radius: 447px;
}

.feature-photo-left {
  float: right;
  width: 485px;
  height: 546px;
  padding-top: 30px;
  padding-left: 38px;
  background-image: url("../images/customers/placeholder-left.png");
}

.feature-photo-right {
  width: 509px;
  height: 543px;
  padding-top: 27px;
  background-image: url("../images/customers/placeholder-right.png");
}

.feature-photo-small > img {
  width: 231px;
  height: 231px;
  border-radius: 231px;
}

.feature-photo-small.feature-photo-left {
  width: 259px;
  height: 247px;
  padding: 0 0 0 12px;
  margin: auto;
  background-image: url("../images/product/placeholder-left.png");
}

.feature-photo-small.feature-photo-right {
  width: 262px;
  height: 264px;
  padding: 17px 0 0 17px;
  margin: auto;
  background-image: url("../images/product/placeholder-right.png");
}

.feature-carousel {
  margin-top: -66px;
}

.feature-carousel-left {
  width: 307px;
  height: 490px;
  padding: 25px 0 0 68px;
  margin-left: -20px;
  background: url("../images/product/phone-frame-left.png") no-repeat 0 0;
}

.feature-carousel-right {
  width: 359px;
  height: 490px;
  padding: 25px 0 0 0;
  margin-left: auto;
  margin-right: -20px;
  background: url("../images/product/phone-frame-right.png") no-repeat 100% 0;
}

.feature-carousel-frame {
  position: relative;
  width: 321px;
  height: 554px;
  padding: 70px 0 0 37px;
  background: url("../images/product/phone.png") no-repeat 0 0;
}

.feature-carousel .owl-carousel,
.feature-carousel .owl-wrapper-outer {
  width: 220px;
}

.feature-carousel .owl-wrapper-outer {
  height: 366px;
  overflow: hidden;
  background: #fff;
}

.screenshot-item {
  display: block;
  width: 220px;
}

.feature-carousel .owl-controls .owl-buttons {
  margin: 0;
}

.feature-carousel .owl-controls .owl-buttons > div {
  position: absolute;
  top: 147px;
  width: 27px;
  height: 70px;
  padding: 0;
  margin: 0;
  border-radius: 0;
  text-indent: -9999px;
  opacity: 0.75;
  background: none;
}

.feature-carousel .owl-controls .owl-buttons > div:hover {
  opacity: 1;
}

.feature-carousel .owl-controls .owl-buttons .owl-prev {
  left: -72px;
  background-image: url("../images/product/carousel-arrow-left.png");
}

.feature-carousel .owl-controls .owl-buttons .owl-next {
  right: -72px;
  background-image: url("../images/product/carousel-arrow-right.png");
}

.feature-row-flex {
  display: flex;
  /*flex-wrap: wrap;*/
  align-items: center !important;
}

/* ==========
   7. Download
   ========== */

.download-title {
  font-size: 30px;
  font-weight: 500;
}

.download-icon {
  width: 261px;
  height: 261px;
  background: url("../images/download/download-icon.png") no-repeat 0 0;
  margin: 30px auto;
}

.download-text {
  margin: 30px 0;
  line-height: 1.2;
  color: #000000;
  font-style: italic;
}

.download-text-big {
  font-size: 20px;
}

.download-frame {
  width: 346px;
  height: 82px;
  padding: 21px 0;
  margin: auto;
  text-align: center;
  background: url("../images/download/download-frame.png") no-repeat 100% 0;
}

.download-frame-big {
  width: 458px;
  height: 110px;
  padding: 28px 0;
  background: url("../images/download/download-frame-big.png") no-repeat 100% 0;
}

.download-frame-huge {
  background: none !important;
}

.download-frame-huge,
.download-frame-huge .download-frame-body {
  position: relative;
  width: 100%;
  height: 263px;
  margin: auto;
}

.download-frame-huge .download-frame-body {
  position: relative;
  width: 1045px;
  height: 263px;
  left: 50%;
  margin-left: -522.5px;
  padding: 55px 0;
  background: url("../images/download/download-frame-huge.png") no-repeat 100% 0;
}

.download-buttons > a {
  display: inline-block;
  margin: 0 8px;
}

.download-frame-big .download-buttons > a {
  margin: 0 12px;
}

.download-feature .feature-title,
.download-feature .feature-text {
  padding: 0 20px;
}

.download-feature .feature-text {
  font-size: 18px;
}

.download-feature-image-connect {
  background-image: url("../images/download/connect-icon.png");
}

.download-feature-image-network {
  background-image: url("../images/download/network-icon.png");
}

.download-feature-image-online {
  background-image: url("../images/download/online-icon.png");
}

.start-frame {
  width: 607px;
  height: 82px;
  padding: 24px;
  margin: auto;
  background: url("../images/product/subtitle-frame.png") no-repeat 100% 0;
}

.start-text {
  font-size: 20px;
  line-height: 1.2;
  text-align: center;
  color: #8f8f90;
}

.start-text > b {
  display: inline-block;
  padding: 5px 15px;
  background: #c8dd99;
  border-radius: 5px;
  font-weight: 500;
  color: #000;
}

.start-text + .download-buttons {
  margin-top: 40px;
}

.download-frame .start-text {
  font-size: 30px;
  color: #000;
}

.download-frame .start-text > b {
  padding: 7px 21px;
  background: #fff;
}

/* ==========
   7. Product
   ========== */

.product-icon {
  width: 251px;
  height: 251px;
  background: url("../images/product/product-icon.png") no-repeat 0 0;
  margin: 30px auto;
}

/* ==========
   8. Contact
   ========== */

.contact-title {
  font-size: 30px;
  font-weight: 500;
}

.contact-block {
  display: flex;
}

.contact-icon {
  margin-right: 20px;
}

.contact-icon > i {
  display: inline-block;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center 0;
}

.contact-text {
  font-size: 20px;
  line-height: 1.6;
}

.contact-text > b,
.contact-text > b a {
  font-weight: 500;
  color: #000;
  text-decoration: none;
}

.form-group label {
  display: none;
}

.form-control {
  margin-bottom: 25px;
  box-shadow: none !important;
  border: solid 1px #d3d3d3;
  border-radius: 4px;
  height: 44px;
  font-size: 20px;
}

.form-control:focus {
  border-color: #00aeef;
}

/* =========
   9. Footer
   =========*/

.footer {
  color: #8f8f90;
  padding-top: 60px;
  padding-bottom: 60px;
}

/* =========
   10. Responsive 
   =========*/

@media (min-width: 1046px) {
  .home .download-buttons img {
    height: 50px;
  }
}

@media (max-width: 1045px) {
  .section {
    overflow-x: hidden !important;
  }

  .home .circle-1 {
    top: 135px;
  }

  .home .circle-2 {
    display: none;
  }
}

@media (max-width: 1045px) and (min-width: 1024px) {
  .download-frame-huge {
    margin-top: -30px;
  }

  .download-frame-huge .download-frame-body {
    transform: scale(0.9);
  }
}

@media (max-width: 1023px) and (min-width: 577px) {
  .download-frame-huge {
    margin: -40px auto -70px;
  }

  .download-frame-huge .download-frame-body {
    transform: scale(0.6);
  }
}

@media (max-width: 992px) {
  .home .circle-1 {
    left: 20%;
    top: 150px;
  }

  .home h1 {
    font-size: 30px;
    font-weight: 500;
  }

  .feature-carousel-left {
    transform: scale(0.85) translateX(-50px);
  }

  .feature-carousel-right {
    transform: scale(0.85) translateX(-15px);
  }

  .section-screenshots .feature-block-left .feature-icon {
    margin-left: -15px;
    margin-right: 10px;
  }

  .section-screenshots .feature-block-left .feature-content {
    margin-right: 20px;
  }

  .section-screenshots .feature-block-right .feature-icon {
    margin-left: 0;
    margin-right: -10px;
  }

  .section-screenshots .feature-block-right .feature-content {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .m-t-130 {
    margin-top: 80px !important;
  }

  .title {
    font-size: 40px;
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .home {
    min-height: 0;
    text-align: center;
  }

  .home .circle-1 {
    display: none;
  }

  .home .container.teaser {
    margin-top: 20px;
  }

  .download-frame-huge {
    margin-top: -60px;
    margin-bottom: -20px;
  }
}

@media (max-width: 768px) and (min-width: 767px) {
  .home .phone-wrapper {
    transform: scale(0.85);
    margin-right: -40px;
    margin-bottom: -40px;
  }

  .feature-photo-left {
    transform: translateX(-25px);
  }

  .feature-photo-right {
    width: 100% !important;
    overflow: hidden;
    transform: translateX(25px);
  }
}

@media (max-width: 767px) {
  .title {
    font-size: 30px;
  }
  
  .home .navbar-brand {
    float: none;
  }

  .home .navbar-brand > img {
    margin: 0 auto;
  }

  .home .container.circles,
  .home .container.header .navbar-toggle,
  .home .container.header .navbar-collapse {
    display: none;
  }

  .home .download-buttons {
    margin-top: 50px;
  }

  .home .phone-wrapper {
    position: relative;
    width: 100%;
    margin-top: 50px;
  }

  .home .phone {
    float: none;
    position: relative;
    left: 50%;
    margin-left: -504px;
  }

  .feature-row-flex {
    flex-direction: column;
  }

  .feature-photo {
    float: none !important;
    margin-top: -25px;
    margin-bottom: 45px;
  }

  .feature-photo-small {
    margin-bottom: 25px !important;
  }

  .feature-block {
    flex-direction: row-reverse;
  }

  .feature-block + .feature-block {
    margin-top: 50px;
  }

  .feature-title {
    font-size: 27px;
  }
  
  .feature-icon {
    display: none;
  }

  .feature-carousel {
    width: 100%;
    background: none !important;
    padding: 0;
    margin: -80px 0 0 -146.5px;
    transform: scale(0.75);
    position: relative;
    left: 50%;
  }

  .feature-frame-text {
    width: auto;
    height: auto;
    background: none !important;
    padding: 0;
    margin-top: 20px !important;
    font-weight: 500;
  }
  
  .text-xs-left .feature-frame-text {
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  .section-screenshots .feature-block-left .feature-content,
  .section-screenshots .feature-block-right .feature-content {
    margin-left: 0;
    margin-right: 0;
  }

  .start-frame {
    width: 100%;
    padding: 10px 0;
    background-position: 50% 0;
  }

  .start-text {
    font-size: 17px;
  }

  .start-text > b {
    margin-top: 6px;
  }

  .download-frame-big {
    width: 100%;
    background-position: 50% 0;
    padding: 32px 0;
  }

  .download-frame-big a {
    margin: 0 6px !important;
  }

  .contact-icon {
    display: none;
  }

  .text-xs-left {
    text-align: left;
  }

  .text-xs-center {
    text-align: center;
  }

  .m-t-xs-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 600px) {
  .home .container.teaser {
    margin-top: 0;
  }

  .home h1 {
    margin: 15px 0 20px 0;
    font-size: 30px;
    font-weight: 500;
  }

  .home p {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6;
  }

  .home .phone-wrapper {
    margin-top: 0;
    margin-bottom: -68px;
  }

  .home .phone {
    margin-left: -467px;
    transform: scale(0.75);
  }

  .download-buttons > a {
    margin: 0 3px;
  }

  .download-frame-huge a {
    margin: 0 6px !important;
  }

  .download-frame-huge img {
    height: 43px;
  }

  .download-frame-huge .download-frame-body {
    width: 100%;
    background-position: 50% 0;
    left: 0;
    margin-left: 0;
    padding: 35px 0 0 0;
    text-align: center;
  }

  .download-frame-huge .start-text {
    font-size: 22px;
  }

  .download-frame-huge .start-text > b {
    margin-top: 10px;
  }
}

@media (max-width: 420px) {
  .download-frame-big a,
  .download-frame-huge a {
    margin: 0 2px !important;
  }
  
  .download-frame-big img,
  .download-frame-huge img {
    height: 42px;
  }
}

@media (max-width: 320px) {
  .home h1 {
    font-size: 27px;
  }

  .download-frame-big img,
  .download-frame-huge img {
    height: 40px;
  }
}

/* ==========
   11. Retina
   ==========*/

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 2000px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 2000px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 2000px), only screen and (min-device-pixel-ratio: 2) and (max-width: 2000px), only screen and (min-resolution: 192dpi) and (max-width: 2000px), only screen and (min-resolution: 2dppx) and (max-width: 2000px) {
  .icon-document {
    background-image: url("../images/icons/checklist@2x.png");
    background-size: 48px 60px;
  }

  .icon-search {
    background-image: url("../images/icons/search@2x.png");
    background-size: 78px 64px;
  }

  .icon-plan {
    background-image: url("../images/icons/plan@2x.png");
    background-size: 59px 59px;
  }

  .icon-tablet {
    background-image: url("../images/icons/tablet@2x.png");
    background-size: 56px 60px;
  }

  .icon-phone {
    background-image: url("../images/icons/phone@2x.png");
    background-size: 40px 70px;
  }

  .icon-clock {
    background-image: url("../images/icons/clock@2x.png");
    background-size: 58px 60px;
  }

  .icon-document-search {
    background-image: url("../images/icons/document-search@2x.png");
    background-size: 51px 60px;
  }

  .icon-phone-in-hand {
    background-image: url("../images/icons/phone-in-hand@2x.png");
    background-size: 61px 46px;
  }

  .icon-phone-notification {
    background-image: url("../images/icons/phone-notification@2x.png");
    background-size: 52px 60px;
  }

  .icon-phone-search {
    background-image: url("../images/icons/phone-search@2x.png");
    background-size: 58px 60px;
  }

  .icon-folder {
    background-image: url("../images/icons/folder@2x.png");
    background-size: 61px 54px;
  }

  .icon-folder-opened {
    background-image: url("../images/icons/folder-opened@2x.png");
    background-size: 62px 58px;
  }

  .icon-home {
    background-image: url("../images/icons/home@2x.png");
    background-size: 59px 59px;
  }

  .icon-bell {
    background-image: url("../images/icons/bell@2x.png");
    background-size: 49px 54px;
  }

  .home {
    background-image: url("../images/home/bg@2x.png");
    background-size: 46px 29px;
  }

  .home .circle-1 {
    background-image: url("../images/home/circle-1@2x.png");
    background-size: 212px 185px;
  }

  .home .circle-2 {
    background-image: url("../images/home/circle-2@2x.png");
    background-size: 259px 255px;
  }

  .home .circle-3 {
    background-image: url("../images/home/circle-3@2x.png");
    background-size: 350px 348px;
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.in {
    background-image: url("../images/logo@2x.png");
    background-size: 173px 138px;
  }

  .home .phone {
    background-image: url("../images/home/phone@2x.png");
    background-size: 708px 538px;
  }

  .feature-frame-text {
    background-image: url("../images/product/text-frame@2x.png");
    background-size: 447px 82px;
  }

  .feature-photo-left {
    background-image: url("../images/customers/placeholder-left@2x.png");
    background-size: 485px 546px;
  }

  .feature-photo-right {
    background-image: url("../images/customers/placeholder-right@2x.png");
    background-size: 509px 543px;
  }

  .feature-photo-small.feature-photo-left {
    background-image: url("../images/product/placeholder-left@2x.png");
    background-size: 259px 247px;
  }

  .feature-photo-small.feature-photo-right {
    background-image: url("../images/product/placeholder-right@2x.png");
    background-size: 262px 264px;
  }

  .feature-carousel-left {
    background-image: url("../images/product/phone-frame-left@2x.png");
    background-size: 307px 490px;
  }

  .feature-carousel-right {
    background-image: url("../images/product/phone-frame-right@2x.png");
    background-size: 305px 490px;
  }

  .feature-carousel-frame {
    background-image: url("../images/product/phone@2x.png");
    background-size: 321px 554px;
  }

  .feature-carousel .owl-controls .owl-buttons .owl-prev {
    background-image: url("../images/product/carousel-arrow-left@2x.png");
    background-size: 27px 70px;
  }

  .feature-carousel .owl-controls .owl-buttons .owl-next {
    background-image: url("../images/product/carousel-arrow-right@2x.png");
    background-size: 28px 70px;
  }

  .download-icon {
    background-image: url("../images/download/download-icon@2x.png");
    background-size: 251px 248px;
  }

  .download-frame {
    background-image: url("../images/download/download-frame@2x.png");
    background-size: 338px 82px;
  }

  .download-frame-big {
    background-image: url("../images/download/download-frame-big@2x.png");
    background-size: 458px 110px;
  }

  .download-frame-huge .download-frame-body {
    background-image: url("../images/download/download-frame-huge@2x.png");
    background-size: 1045px 263px;
  }

  .download-feature-image-connect {
    background-image: url("../images/download/connect-icon@2x.png");
    background-size: 247px 282px;
  }

  .download-feature-image-network {
    background-image: url("../images/download/network-icon@2x.png");
    background-size: 246px 283px;
  }

  .download-feature-image-online {
    background-image: url("../images/download/online-icon@2x.png");
    background-size: 264px 283px;
  }

  .start-frame {
    background-image: url("../images/product/subtitle-frame@2x.png");
    background-size: 607px 82px;
  }

  .product-icon {
    background-image: url("../images/product/product-icon@2x.png");
    background-size: 251px 248px;
  }
}

.thank-you{
  font-weight: 500;
  line-height: 1.2;
  color: #6da92e;
  text-align: center;
}

.contact-soon{
  text-align: center;
}

.telephone {
  padding-top: 25px;
}
